// Light Theme
@import "bootstrap.scss";
@import "./app.scss";
// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

//RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

@import "icons.scss";


// Theme Custom Scss for react version
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.rdw-editor-main {
  border: 1px solid $card-border-color !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.card-header{
  font-size: large;
  font-weight: bolder;
}

.MuiTypography-h6{
   white-space: initial !important;
  // overflow: scroll !important;
  // text-overflow:initial !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.rdw-editor-wrapper {
  min-height: 180px !important;
}

.ReactModal__Overlay {
  z-index: 10001 !important;
}

.react-datepicker-wrapper {
  .form-control {
    width: 405% !important;
  }
}

.dropzone {
  h3 {
    text-align: center !important;
    padding-top: 100px !important;
  }
  div {
    min-height: 200px !important;
  }
  min-height: 200px !important;
}

input[type="radio"] + label {
  cursor: pointer !important;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  color: $primary !important;
}
.input-color{
  color: $input-color !important;
}
.rangeslider__fill{
  background-color: $primary !important;
}
.rating-container {
  background-color: transparent !important;
}

.file-upload {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #02a499;
  border-radius: 5px;
  padding: 8px 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.file-upload:hover {
  background-color: #02a462;
}

.file-upload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 100px;
  cursor: pointer;
}

.file-upload span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.file-upload .browse {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  font-weight: 400;
  font-size: 12px;
  color: #fff;
}
.carousel-control-next .carousel-control-prev{
  background-color: $primary !important;
  font-size: 20px !important;
  color: #fff !important;
  border-radius: 50% !important;
  padding: 5px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  transition: all 0.2s ease-in-out;
}